import { memo } from 'react';
import {
  ContentError,
  BtnRefreshPage,
  TitlePage,
  InfoError,
} from './error.styled';

const ErrorContainer = ({ error, resetErrorBoundary }) => (
  <ContentError>
    <TitlePage>Something went wrong :(</TitlePage>
    <InfoError>
      <p>
        {error?.name}: "{error?.message}"
      </p>
    </InfoError>
    <BtnRefreshPage variant="outlined" onClick={resetErrorBoundary}>
      Try again
    </BtnRefreshPage>
  </ContentError>
);

export default memo(ErrorContainer);
