import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const ErrorPage = styled.div`
  height: 100vh;
  display: flex;
`;
export const ContentError = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    flex-grow: 0;
    width: calc(100% - 80px);
    margin-left: auto;
  }
  @media (max-width: 1024px) {
    width: calc(100% - 66px);
  }
  @media (max-width: 610px) {
    width: 100%;
  }
`;

export const BtnRefreshPage = styled(Button)`
  top: 40px;
`;

export const TitlePage = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 34px;
`;

export const InfoError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
`;
